import React from 'react';

import styles from './consulting.module.scss';
import { Link } from 'gatsby'

import Layout from '../../components/layout';

import { consulting_team } from '../../images/team/';

import {
  college,
  boarding,
  mentor
} from '../../images/stock';

import {
  apple,
  hat,
  qa,
} from '../../images/icons';

export default () => (
  <Layout>
    <div className={styles.consultingPage}>
      <div className={styles.topSection}>
        <div className={styles.wrapper}>
          <div className={styles.contentWrapper}>
            <p className={styles.header}>A Blueprint for future success</p>
            <p className={styles.description}>Our counseling programs focus on developing students holistically. Each student is provided an individualized plan with benchmarks and goals and is mentored by two counselors who are experts in boarding school or college admissions.</p>
          </div>
          <div className={styles.imageWrapper}>
            <img src={consulting_team} />
          </div>
        </div>
      </div>
    
    <div className={styles.cardSection}>
      <Link to="/products/high-school-counseling" className={styles.card}>
        <img src={boarding} />
        <div className={styles.inset}>
          <img src={apple}/>
        </div>
        <p>High School Counseling</p>
      </Link>
      <Link to="/products/college-counseling" className={styles.card}>
        <img src={college} />
        <div className={styles.inset}>
          <img src={hat}/>
        </div>
        <p>College Counseling</p>
      </Link>
      <Link to="/products/academic-mentorship" className={styles.card}>
        <img src={mentor} />
        <div className={styles.inset}>
          <img src={qa}/>
        </div>
        <p>Academic Mentorship</p>
      </Link>
    </div>
    </div>
  </Layout>
)